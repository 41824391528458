import React from "react";
import Layout from "../layouts/home";
import SEO from "../components/SEO";

class Notfound extends React.Component {
  render() {
    return (
      <Layout location="/404" cssClass="notfound">
        <SEO
          title="Page not found"
          description="The page you are looking for cannot be found."
        />
        <section id="header" className="p-top p-bottom text-center bg-charcoal">
          <div className="featured">
            <h1>Page not found</h1>
            <div className="lead">
              <p>The page you are looking for cannot be found.</p>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Notfound;
